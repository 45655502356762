@import "~bootstrap/scss/mixins/breakpoints";
@import 'sfpvariables';
@import 'sfpmixins';
@import 'sfpfunctions';
//common used
@import 'icons/material-design.scss';
@import 'icon-widgets/icon-widget-6.scss';
@import 'pages/error-page.scss';
@import 'pages/form.scss';


//adding back any needed items TBD

$product-line-color: #707070;

$sort-color: $primary;

.green-test {
  background-color: get-bscolor("green", "500");
}

strong, .font-weight-bold, .fw-bold, .text-bold {
  font-weight: 500 !important;
}

//ant d global issues

h1, h2, h3, h4, h5, h6 {
  color: inherit !important;
}

//test IOS zoom issues
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  select:focus, textarea:focus, input:focus {
          font-size: 16px !important;
      }
  }

  @media (min-width: 768px) {
    select:focus, textarea:focus, input:focus {
      font-size: 14px !important;
    }
  }

//ui buttons
//pseudo btn-block as no longer supported
.btn {
  //font-weight: normal;
  &.btn-block {
    width: 100%;
  }
  &.btn-icon {
    vertical-align: middle;
    display: inline-flex;
    align-items: center;
    i {
      margin-right: 5px;
    }
  }
};
//CHANGE THIS
/* ::-webkit-scrollbar {
	display: none;
	width  : 0 !important;
} */

//DO WE WANT?
/* Scrollbar */
*::-webkit-scrollbar {
  width: 12px !important;
}

*::-webkit-scrollbar-track {
  background: transparent !important;
  // box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

*::-webkit-scrollbar-thumb {
  background: rgb(224, 224, 224) !important;
  border: 4px solid rgba(0, 0, 0, 0) !important;
  background-clip: padding-box !important;
  border-radius: 5px !important;
}

*::-webkit-scrollbar-thumb:hover {
  background: rgb(219, 219, 219) !important;
  border: 1px solid rgba(0, 0, 0, 0) !important;
  background-clip: padding-box !important;
  border-radius: 4px !important;
  transition: all .2s ease-in !important;
}


//BS 5 no form group or form row
.form-group {
  margin-bottom: 1rem;
}

.hidden {
  display: none !important;
}


.badge {
/*   &.badge-sm {
    @include font-size(10px);
    line-height: 0.6;
  } */
  &.badge-lg {
    @include font-size(14px);
    line-height: 1;
    padding: 6px 12px;
  }
}

//react bs table 2

.react-bootstrap-table .row-expansion-style p {
  margin-bottom: .4rem;
}

//Column sizing
.react-bootstrap-table table {
  // Fix columns content overlap
  table-layout: auto !important;
}
//sorting - change title
.react-bootstrap-table th.sortable {
  &[aria-label$="asc"] {
      color: $sort-color !important;
  }
  &[aria-label$="desc"] {
    color: $sort-color !important;
  }
}
//column expander, selection - needs override -work  sticky, bg
.react-bootstrap-table {
  .selection-cell, .selection-cell-header { //expand-cell-header
    min-width: 40px;
    text-align: center;
  }
  .selection-cell {
    vertical-align: middle;
  }
th[data-row-selection] {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: $gray-200;//alter based on header table settings
}
}

.react-bootstrap-table.wrap-sticky {
  // for sticky header
  max-height: 440px;
}


//THESE ARE BSTABLE 1

//override table tests

.react-bs-table .table-bordered {
  border: 0;
  border-color: #dee2e6;
  outline: none !important;
  thead {
    border-top: 0 !important;
    tr {
      border-top: 0 !important;
    }
  }
}
//pagination size per page make entire li clickable

.react-bs-table-sizePerPage-dropdown {
	.dropdown-item a {
  	display: block;
		color: #d32f2f !important;
	}
}

.react-bs-table-sizePerPage-dropdown {
	.dropdown-menu a {
  	display: block;
		color: #d32f2f !important;
	}
}


.react-bs-table-sizePerPage-dropdown {

	.dropdown-toggle::after {
		display: none !important;
	}

	#pageDropDown {
		border-color: #e0e0e0 !important;
	}
}



// confirm dialog customization
.react-confirm-alert-overlay {
  z-index: 1099 !important;
  font-size: 1rem !important;

  h1 {
    font-size: 1.3rem !important;
    margin-bottom: 12px;
  }

  button {
    height: 32px;
    min-width: 120px;

    font-size: .9rem !important;
    font-weight: bold;
    text-transform: uppercase;
  }
}



//causes issues
/* .ant-carousel .slick-slide {
  text-align : center;
  height     : 160px;
  line-height: 160px;
  overflow   : hidden;
} */
.ant-carousel .slick-slide .productslide {
	line-height: 22px;
	height: 410px;
}
//TBD FORM
//normalize antd & bootstrap & rw form fields
//this is too generic
.form-control[class*="ant-"] {
  &.ant-select {
    padding: 0;
  }
	.ant-input, .ant-select-selector {
		border: none !important;
    background-color: transparent !important;
	}
  .ant-picker-input input {
		line-height: inherit;
    font-size: inherit;
	}
  &.ant-picker-range {
    display: inline-flex;
  }
}

.ant-progress-steps-outer {
  .ant-progress-text {
    width:auto;
  }
}

//CHECK TBD
/* .rw-state-focus {
	z-index: 2000 !important;
}

.rw-widget-container {
  //background-color: #fff;
  //border: inherit;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0px;
  //height: 31px;
}
.rw-widget-input, .rw-filter-input {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.rw-widget-picker {
  height: 31px;
}
.rw-input {
  padding: 0;
} */

.rw-widget .form-control.rw-widget-picker {
  display: grid;
  min-height: unset;
  padding-right: 0;
  padding-left: 0;
}

.rw-widget .form-control .rw-multiselect-input {
  height: unset;
}

.rw-widget .form-control .rw-multiselect-tag {
  min-height: 22px;
  font-size: 13px;
  margin-top: 1px;
  margin-bottom: 1px;
}


.rw-widget .is-invalid.form-control .rw-btn {
  display: none;
}

.rw-widget .is-valid.form-control .rw-btn {
  display: none;
}

.rw-multiselect .rw-input-reset {
  // correction of overflow on rw multiselect input
  width: calc(100% - 00.875em) !important; //default horizontal padding on rw-multiselect is 0.857
}

.rw-listbox {
  &.rw-state-focus {
    box-shadow: none;
  }
}

.rw-list-option.rw-state-selected, .rw-state-selected.rw-list-option-create {
  background-color: #007bff;
  border-color: #42a5f5; //#007bff;
  color: #fff;
}

.required:after {
  content:" *";
  color: red;
}

.required-alt:after {
    margin-left: 4px;
    color: #ff9800;
    font-size: 14px;
    font-family: SimSun,sans-serif;
    line-height: 1;
    content: "*";
}

.ant-card-cover {
  span{
    img{
      display: block;
      width: 100%;
    }
  }
}

.anticon {
	vertical-align: 0 !important;
}

$dark: #304c68; // SFP BLUE

//CAROUSEL
.ant-carousel {
  .slick-dots-bottom {
    bottom: 0px;
  }
  .slick-dots {
    position: relative;
    height: 50px;
    width: 100%;
    margin: 0;
    padding: 0;
    gap: 15px;
    margin-top: 20px;
    
    li {
      width: 50px !important;
      text-indent: initial;
    }
  }

  .slick-dots-for-products {
    min-width: 200px;
    height: auto!important;
    gap: 1%!important;

    ul {
      display: flex!important;
      gap: 5px;
      margin: 0;
      padding: 0;
      flex-wrap: wrap;
    }

    li {
      display:flex!important;
      height: auto!important;
    }
  }

  .slick-dots-thumbs {
    display:inline!important;
    width:100%;
    margin-left:0px;
  }

  .img-border {
    box-sizing: border-box;
  }

  .img-fit {
    object-fit: scale-down;
    height: 280px;
    width: 100%;
  }

  .img-size {
    width: auto;
    max-height: 60vh;
  }

  .img-thumb {
    width: 50px;
    height: 50px;
    object-fit: scale-down;
  }
  .img-wrap {
    float:right;
    width: 99% !important
  }
}
//arrow fixes
.ant-carousel {
  .slick-next {
    right: -20px;
    width: 50px;
  }
  .slick-next::before {
    content: '\203A';
    color: $dark;
    font-size: 60px;
  }
}

.ant-carousel {
  .slick-prev {
    left: -20px;
    width: 50px;
    z-index: 2;
  }
  .slick-prev::before {
    content: '\2039';
    color: $dark;
    font-size: 60px;
  }
}

.ant-image-carousel {
  .ant-carousel {
    .slick-dots-bottom {
      bottom: 0px;
    }
    .img-border {
      border: 1px solid $product-line-color !important;
    }

    .slick-dots {
      li {
        text-indent: unset;
        width: auto;
      }
    }

    .img-size {
      width: 99%;
    }
    .img-thumb {
      max-width: 50px;
      max-height: 50px;
    }
  }
}

.dashboard-carousel {
  .ant-carousel {
    .slick-dots-bottom {
      bottom: -8px;
    }
    .slick-track {
      display: flex;
  
      .slick-slide {
          display: flex;
          height: auto;
          align-items: stretch;
          //justify-content: center; //optional
          > div {
            width: 98%;
          }
      }
  }
    .img-thumb {
      max-width: 50px;
      max-height: 50px;
    }
  }
}

// ant-d drawer
.ant-drawer {
  z-index: 1033 !important;
}

//SEEMS OK IN NEW VERSION?
//USED to fix issues with dropdown cutoff - use as needed
//IMPORTANT - set the parent container to overflow hidden to avoid Mobile Modal display issues (IOS etc)
//also fixed header scroll breaks with collapse visible if not overflown
/* .ant-collapse-content-active {
  overflow: visible !important;
  overflow-y: hidden;
} */

.ant-collapse-content > .ant-collapse-content-box {
  padding: 8px;
}

// react photo gallery
.react-photo-gallery--gallery {
  .m-5 {
    margin: 0 !important;
    margin-top: 16px !important;
  }
}

// react-responsive-carousel
.carousel {

  .thumbs {
    display: inline-flex;
    align-items: center;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    .thumb img {
      max-height: 80px;
      object-fit: scale-down;
    }
  }

  .control-arrow {
    background: rgba(179, 179, 179, 0.514) !important;
    // background-color: black;
    border-radius: 6px;
  }

  .slider-wrapper {
    max-height: 280px;

    div, ul, li, img {
      max-height: 280px;
      align-self: center;
    }

    img {
      object-fit: contain;
    }
  }
}

//FROM MAIN

#root {
	height    : 100%;
	min-height: 100%;
  overflow: hidden;
}

@include media-breakpoint-down(sm) {
	#root {
		background-color: white;//MOBILE MAKE WHITE
	}
}

.divider {
	height: 2px;
	margin: 15px 0;
}

.separator {
	flex: 1;
}
.bg-transparent {
	background-color: transparent !important;
}

//From CUSTOM

.td-column-overflown {
	overflow: visible !important;
}

.circle-num {
	width             : 30px;
	pointer-events    : none;
	cursor            : default;
	background        : #f6f6f6;
	border            : 1px solid #b3b3b3!important;
	-moz-box-sizing   : border-box;
	-webkit-box-sizing: border-box;
	box-sizing        : border-box;
	border-radius     : 50%;
	text-align        : center;
	line-height       : 30px;
	color             : #333;
	height            : 30px;
	padding-top       : 0;
	z-index           : 1;
	position          : relative;
}

.criteria-input {
  width        : 100%;
  border       : none;
  border-bottom: 1px solid #ccc!important;
}

//from custom ant/carousel product profile

.carousel-container {
  height      : 400px;
  width       : 100%;
  margin-right: auto;
  margin-left : auto;
}

.card-gray {
  background-color: #d8dce3;
}

.jumbotron {
  margin-bottom: 0px;
}
.img-responsive {
    display  : block;
    max-width: 100%;
    height   : auto;
}

.user-form {
  box-shadow: none;
}

/* .vendor-logo .ant-upload {
  width: 100%!important;
} */

.jumbotron-actions {
	.btn {
		box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
			0px 4px 5px 0px rgba(0, 0, 0, 0.14),
			0px 1px 10px 0px rgba(0, 0, 0, 0.12);
		&:hover {
			box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
				0px 4px 5px 0px rgba(0, 0, 0, 0.14),
				0px 1px 10px 0px rgba(0, 0, 0, 0.12);
		}
	}
}

 /* Rules for sizing the icon. */
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

//vendor profile
.profile-left-body {
  margin-top: 20px;
}

.product-line-count {
  float       : right;
  margin-right: 20px;
}

.vertical-align-middle {
    vertical-align: middle;
}

.vendor-download-form {
  padding: 0px 40px;
}

.outline-icon {
  color                    : black;
  -webkit-text-fill-color  : white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;

}

.custom-table-filter{
  flex-flow: wrap;
}

//CHECK THESE

//_dropdown
.dropup, .dropdown {
  position: relative;
}

.caret {
  display       : inline-block;
  width         : 0;
  height        : 0;
  margin-left   : 2px;
  vertical-align: middle;
  border-top    : 4px dashed;
  border-top    : 4px solid \9;
  border-right  : 4px solid transparent;
  border-left   : 4px solid transparent;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  border-top   : 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content      : "";
}

////

.col-options {
	span{
		flex-grow: 1;
		width    : 50%;
	}
}

.img-circle {
  border-radius: 50%;
}

.media-object {
    display: block;
}
.user-list .media-object {
    width  : 42px;
    padding: 2px;
    border : 2px solid #dbdfe6;
    padding: 0px;
}

.user-list {
  padding: 10px;
  margin : 0px;
}

.pending-approval {
  background:#FFBB7D!important;
  td{background:#FFBB7D!important;}
}
//d8f3f7
.product-even {
  background:#fcfcfd!important;
  td{background:#fcfcfd!important;}
}
//_dropdown

.closed {
  background:#FFE7E3!important;
  td{background:#FFE7E3!important;}
}

.tr-invisible {
	td {
		background: red !important;
	}
}

.orders-table {
	td {
		background: none !important;
	}
}
.new-order {
	animation: 5s ease-in-out pulse;
}

.credit-card-checkout {
	p {
		font-size: 16px;
	}
	span {
		margin-left: 10px;
	}
	div.btn-secondary {
		margin-left: 7.5%;
		margin-top:1em;
	}
	border: 1px solid black;
	padding-top:1em;
	padding-bottom: 2em;
	width:600px;
	margin: auto auto;
}

@keyframes pulse {
	from { background: rgba(#FFDC00, 0.3)}
	to { background: white}
}


.supplier-dashboard .widget .table thead th {
	padding: 0.25rem;
	background-color: inherit;
}
.supplier-dashboard .widget .table td {
	padding: 0.25rem;
	border: none;
}

.supplier-dashboard .widget.card {
	border-color: get-bscolor("indigo", "800");
}

.supplier-payments-container .ant-collapse-header {
  color:white !important;
}

.bbb-info {
	h6 {
		font-size: 0.85rem !important;
	}
}

.vendor-summary{
	.ql-editor{
		min-height: 150px !important;
		//height: 1000px !important;
	}
}

.sample-form{
  .ql-editor{
    min-height: 250px !important;
  }
}

.disabled-pricing {
	td {
		background: red !important;
	}
}

blockquote.onboarding{
  font-size: 1.4em;
//   margin:50px auto;
  font-family:Open Sans;
  font-style:italic;
  color: #555555;
  padding:1.2em 30px .8em 40px;
  border-left:8px solid #555555;
  line-height:1.6;
  position: relative;
  //background:#EDEDED;
  background:#ebf8fe
}

blockquote::before.onboarding{
  font-family:Arial;
  content: "\201C";
  color:#78C0A8;
  font-size:4em;
  position: absolute;
  left: 10px;
  top:-10px;
}

blockquote::after.onboarding{
  content: '';
}

.circle-progress {
  position: relative;
  background-color: white;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
}

.circle-progress--border,
.circle-progress--text {
  position: absolute;
  width: 35px;
  height: 35px;
  margin: 0px;
}

.circle-progress--border {
  border: 2px dashed black;
  border-radius: 100%;
}

.circle-progress--text {
  font-family: sans-serif;
  font-size: 18px;
  line-height: 31px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}

.circle-submitted {
  position: relative;
  background-color: white;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
}

.circle-submitted--border,
.circle-submitted--text {
  position: absolute;
  width: 35px;
  height: 35px;
  margin: 0px;
}

.circle-submitted--border {
  border: 2px solid black;
  border-radius: 100%;
}

.circle-submitted--text {
  font-family: sans-serif;
  font-size: 18px;
  line-height: 31px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}


.circle-approved {
  position: relative;
  background-color: black;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
}

.circle-approved--border,
.circle-approved--text {
  position: absolute;
  width: 35px;
  height: 35px;
  margin: 0px;
}

.circle-approved--border {
  border: 2px solid black;
  border-radius: 100%;
}

.circle-approved--text {
  font-family: sans-serif;
  font-size: 20px;
  line-height: 35px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color:white;
}

.display-enter {
  opacity: 0;
  transform: scale(0.9);
}
.display-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.display-exit {
  opacity: 1;
}
.display-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.onboarding-steps-icon {
  display: inline-block;
  width: 50px;
    height: 50px;
    margin-right: 6px;
    font-size: 32px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    line-height: 48px;
    text-align: center;
    // border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 32px;
    -webkit-transition: background-color 0.3s, border-color 0.3s;
    -o-transition: background-color 0.3s, border-color 0.3s;
    transition: background-color 0.3s, border-color 0.3s;
}


// .onboarding-steps-icon::after {
//     position: absolute;
//     top: 26px;
//     left: 77%;
//     display: block;
//     width: 114px;
//     height: 4px;
//     background: #8db5e0;
//     content: '';
// }

// .disable-after:after {
//   content: none;
// }

.onboarding-step-separator {
   position: absolute;
    top: 20px;
    left: 72%;
    display: block;
    width: 55%;
    height: 4px;
    background: #8db5e0;
    content: '';
}

.onboarding-step-title {
  font-size:22px;
  margin-top:5px;
}

.onboarding-step-status {
  margin-top:20px;
}

.fixAnchors a {
	color: #0288d1!important;
}
.fixAnchors a:hover {
	color: #17a2b8!important;
}

.vendor-email-modal {
  @media (max-height: 650px){
    top: 7% !important;
  }
  @media (min-height: 651px){
    top: 20% !important;
  }
}

.button-confirm-sample {
  color: #4B5BB9;
  font-weight: bold;
  cursor: pointer;
}

.product-banner {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 35px;
  background-color: rgb(255, 197, 2);
  padding-top: 8px;
  font-size: 17px;
  font-weight: bold;
}

.product-sale-icon {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 70px;
  height: 70px;
}

.supplier-product-table-title {
  font-size: 15px;
  color: white;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.supplier-product-table-container {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.supplier-product-variable-weight {
  color: rgb(212, 96, 96);
	font-size: 12px;
	font-weight: bold;
}

.checkbox-lg {
  margin-top: 5px;
  font-size: 15px;
}

.sample-request-items-container {
  position: relative;
  padding-bottom: 48px;

  @media (min-width: 1200px) {
    padding-bottom: 0;
  }

  .sample-request-item-selector {
    position: absolute;
    display: flex;
    flex-direction: row;
    left: 0;
    bottom: 0;

    @media (min-width: 1200px) {
        right: 0;
        top: 0;
        left: unset;
        bottom: unset;
    }
  }
}

.pricing-calculator {
  font-style: italic;
  justify-content: space-between;
  padding: 10px;

  .small-size {
    width: 100px;
  }

  .medium-size {
    width: 150px;
  }

  .item-blue {
    color: #0288d1 !important;
    min-width: 100px;
  }
  
  .operator {
    font-size: 25px;
    margin-top: 10px;
  }

  .calc-item {
    width: 100%;

    @media (min-width: 768px) {
      width: auto;
    }
  }
  
  .separator {
    border: 1px dashed;
    width: 100%;
    height: 1px;
    margin: 20px 0;

    @media (min-width: 768px) {
      max-width: 1px;
      height: 60px;
      margin: 0;
    }
  }

  .item-green {
    color: #64dd17 !important;
    min-width: 100px;
  }
  
  .calc-button {
    background-color:  #0288d1 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 5px;
    border-color: transparent;
    box-shadow: 1px 1px;
    margin-top: 20px;

    @media (min-width: 768px) {
      margin-top: 0;
    }
  
    .title {
      color: white;
    }

    .icon {
      font-size: 25px;
    }
  }
}

.terms-of-use {
  font-family: sans-serif;
  font-size: 18px;

  .title {
    font-weight: bold;
  }
}

.terms-of-use-link {
  padding: 1px 0 0 5px;
}

.product-image {
  position: relative;

  .delete-btn {
    position: absolute;
    bottom: 0;
    right: 9px;
    background: rgba(100,100,100,0.6) !important;
    font-size: 20px;
  }
}

.favorite-btn {
  cursor: pointer;
}

.checkbox-fa {
  cursor: pointer;
  label{
    cursor: pointer;
    margin-left: 5px;
  }
}

.filter-fa {
  display: flex;

  i::before {
    font-size: 1.5rem;
  }

  label {
    font-size: 16px;
    margin-top: 4px;
  }
}
.guide-label{
  font-style: italic;
}
.standard-file-upload .ant-upload-list-item-card-actions {
  opacity: 1 !important;
}

.badge-display {
  //white-space: normal;
  word-break: break-word;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  padding: 8px 12px 8px;
  //margin-top: 2px;
  //margin-bottom: 2px;
  &.compact {
    font-size: 12px;
    border-radius: 4px;
    //line-height: 1;
    padding: 6px 8px;
  }
  &.tall {
    line-height: 1.2;
  }
}

.btn-place-order {
  height: 35px;
}
.product-store-filter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.order-cart-footer{
  justify-content: space-between;
}

///PRODUCT PROFILE TBD

.product-profile {
  line-height: 1.2rem;
  .carousel-mobile-wrap {
    width: 100%;
    //height: 360px;
  }
  .carousel-wrap {
    width: calc(81%);
    margin: 0 auto;
  }
  .badge-tag {
    //white-space: normal;
    word-break: break-word;
    //line-height: 17px;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 400;
    border-radius: 5px;
    padding: 8px 12px 8px;
    //margin-top: 2px;
		//margin-bottom: 2px;
    &.compact {
      font-size: 12px;
      border-radius: 4px;
      //line-height: 1;
      padding: 6px 12px;
    }
    &.tall {
      line-height: 1.2;
    }
  }

  .badge.badge-warning {
    color: rgb(255, 255, 255) !important;
    //padding: 8px 12px;
    //line-height: 1;
  }

  hr {
    border-top: 1px solid $product-line-color !important;
  }

  hr.full-page {
    border-top: 1px solid $product-line-color !important;
    margin: 0px -20px;
    border: 0;
    &.line-shadow {
      box-shadow: 0px 1px 6px 0px #21212175;
      border-top: 2px solid #616161 !important;
    }
  }
  .react-bs-table {
  th {
    background-color: #ffffff !important;
    color: black !important;
  }
  table {
    th,
    td {
      //padding: $table-cell-padding;
      //vertical-align: top;
      border-top: none;
    }
  }
}
  .table-head {
    border-bottom: 1px solid $product-line-color !important;
  }
  .rw-widget-container {
    //background-color: #fff;
    //border: inherit;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0px;
    //height: 31px;
  }
  .rw-widget-input, .rw-filter-input {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .rw-input {
    padding: 0;
  }
  .ant-descriptions {
    .ant-descriptions-title {
      margin-bottom: 6px;
      font-size: .95rem;
      font-weight: 500;
    }
    tr.ant-descriptions-row {
      text-align: center;
      vertical-align: top;
    }

    .ant-descriptions-item {

      .ant-descriptions-item-label {
        font-weight: 500;
        line-height: 1.2;
      }
      .ant-descriptions-item-label::after {
        content: none;
      }
      .ant-descriptions-item-content {
        line-height: 1.2;
      }
    }
  }

  .ant-image-carousel {
    .ant-carousel {
      .slick-dots {
        li {
          display:inline;
        }
      }
      .slick-dots-bottom {
        width:95%;
        margin:auto;
        display:block!important;
      }
    }
  }
}

//match tp partner specific
$baseSubBackgroundColor: #3e6284;
//Cart Orders
.cart-orders {
  position: relative;
  top: 12px;
  border-radius: 4px;
  max-height: 640px;
	overflow-x: auto;
  @include box-shadow-3;
  @include media-breakpoint-down(sm) {
    width: 100vw !important;
    max-height: none;
    overflow-x: unset;
  }
  .card {
    border: 1px solid rgba(0, 0, 0, 0.5);
    background-color: $baseSubBackgroundColor;
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 2px 0px !important;
  }
  .ant-collapse-borderless {
    border-radius: 0;
  }
  .ant-collapse-header {
    background-color: #f5f7fa;
    line-height: 1.2 !important;
  }
  .ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 8px;
    padding-top: 8px;
  }

  .list-group-item {
    border-color: #e0e0e0;
  }
  h6 {
    font-size: 14px;
    line-height: 1.1;
    margin: 0;
  }
  strong, .font-weight-bold {
    font-weight: 500 !important;
  }

  .card-header, .card-footer {
    background-color: $baseSubBackgroundColor

  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

//general
.fs-px-12 {
  font-size: 12px;
}

.fs-px-13 {
  font-size: 13px;
}

.fs-px-14 {
  font-size: 14px;
}
.fs-px-16 {
  font-size: 16px;
}

.flex-spacer-4 {
  flex: 4;
  min-width: 0;
}
.flex-spacer-3 {
  flex: 3;
  min-width: 0;
}

.flex-spacer-2 {
  flex: 2;
  min-width: 0;
}
.flex-spacer-1 {
  flex: 1 1 0;
  min-width: 0;
}
.flex-spacer-1of3 {
  flex: 1 1 33%;
  min-width: 0;
}

.max-w-250 {
  max-width: 250px;
}
.max-w-100 {
  max-width: 100px;
}

//holdovers to make compatible
//font size in rem
.text-xs {
  font-size: 0.8rem !important;
}
.text-sm {
  font-size: 0.95rem !important;
}
.text-default {
  font-size: 1rem !important;
}
.text-lg {
  font-size: 1.2rem !important;
}

.mb-2-5 {
  margin-bottom: .75rem;
}
.z-index-10 {
  z-index: 10;
}


.mui-paper {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: rgba(0,0,0,0.2) 0px 2px 1px -1px, rgba(0,0,0,0.14) 0px 1px 1px 0px, rgba(0,0,0,0.12) 0px 1px 3px 0px;
  &.elev-2 {
    box-shadow: rgba(0,0,0,0.2) 0px 3px 1px -2px, rgba(0,0,0,0.14) 0px 2px 2px 0px, rgba(0,0,0,0.12) 0px 1px 5px 0px;
  }
  &.elev-3 {
    box-shadow: rgba(0,0,0,0.2) 0px 3px 3px -2px, rgba(0,0,0,0.14) 0px 3px 4px 0px, rgba(0,0,0,0.12) 0px 1px 8px 0px;
  }
  &.elev-4 {
    box-shadow: rgba(0,0,0,0.2) 0px 2px 4px -1px, rgba(0,0,0,0.14) 0px 4px 5px 0px, rgba(0,0,0,0.12) 0px 1px 10px 0px;
  }
}

.grow-link {
  transition: all .2s ease-in-out; 
  transform-origin: center center;
  &:hover {
    transform: scale(1.05);
    //color: rgb(225, 200, 17);
  }
}




